import React from 'react'
import { IExternalResourceCard } from '../../../../types/resources'
import { Link } from 'gatsby'
import { Box, Button, Flex, Heading, Image, Text } from '../../../../atoms'
import { ExternalResourceCardWrapper } from './ExternalResourceCard.styled'

function ExternalResourceCard(props: IExternalResourceCard): JSX.Element {
  const { name, excerpt, thumbnail, link, buttonLabel } = props

  return (
    <a href={link} rel={'noreferrer noopener'} style={{ height: '100%', display: 'block' }}>
      <ExternalResourceCardWrapper
        position={'relative'}
        flexDirection={'column'}
        justifyContent={'flex-end'}
        alignItems={'stretch'}
        p={[5, 5, 6]}
        borderRadius={8}
        width={'100%'}
        height={'100%'}
        backgroundColor={'black'}
        overflow={'hidden'}
      >
        {thumbnail && (
          <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} zIndex={0}>
            <Image
              alt={thumbnail.alt ?? ''}
              image={thumbnail.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        )}
        <Flex
          flex={'0 0 auto'}
          position={'relative'}
          flexDirection={'column'}
          alignItems={'stretch'}
          justifyContent={'space-between'}
          zIndex={2}
        >
          <Box flex={'1'}>
            <Heading as={'h3'} type={'heading3'} color={'white'}>
              {name}
            </Heading>

            <Box mt={3}>
              <Text type={'small'} color={'white'}>
                {excerpt}
              </Text>
            </Box>
          </Box>

          {buttonLabel && (
            <Flex flex={'0 0 auto'} mt={4}>
              <Button buttonType={'secondary'} size={'sm'} label={buttonLabel} />
            </Flex>
          )}
        </Flex>
      </ExternalResourceCardWrapper>
    </a>
  )
}

export default ExternalResourceCard
