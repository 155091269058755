import React from 'react'
import { SectionFeaturedExternalResources } from '../../../types/sections'
import { Box, Button, Flex, Heading } from '../../../atoms'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { FeaturedExternalResourcesCol, FeaturedExternalResourcesRow } from './FeaturedExternalResources.styled'
import { ExternalResourceCard } from '../../resources/external-resources'

function FeaturedExternalResources(props: SectionFeaturedExternalResources): JSX.Element {
  const { sectionId, id, headline, button, externalResources } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Flex
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={['flex-start', 'flex-start', 'flex-end']}
              gap={16}
              flexDirection={['column', 'row', 'row']}
            >
              <Heading as='h2' type='heading1'>
                {headline}
              </Heading>
              {button && button[0] && (
                <Button buttonType={button[0].buttonType} link={button[0].link} label={button[0].label} />
              )}
            </Flex>
          </Col>
        </Row>
        <Box mt={[6, 7, 8]}>
          <FeaturedExternalResourcesRow className={'noscrollBar'}>
            {externalResources.map((externalResource) => (
              <FeaturedExternalResourcesCol key={externalResource.id} xs={10} sm={5} md={4}>
                <Box mb={[6, 7, 0]}>
                  <ExternalResourceCard {...externalResource} />
                </Box>
              </FeaturedExternalResourcesCol>
            ))}
          </FeaturedExternalResourcesRow>
        </Box>
      </Grid>
    </Box>
  )
}

export default FeaturedExternalResources
